import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Radio, Modal } from 'antd';
import axios from "axios";
import { Switch } from 'antd';

function Manual(props) {

    const [ModalOpen, setModalOpen] = useState(false);
    //const [BuySellMode, setBuySellMode] = useState('');
    const [ManualOrderQty0, setManualOrderQty0] = useState(0.002);
    const [ManualOrderQty1, setManualOrderQty1] = useState(0.05);
    const [ManualOrderPrice0, setManualOrderPrice0] = useState(62000);
    const [ManualOrderPrice1, setManualOrderPrice1] = useState(2300);
    const [ManualOrderSymbol0, setManualOrderSymbol0] = useState('BTCUSDT');
    const [ManualOrderSymbol1, setManualOrderSymbol1] = useState('ETHUSDT');
    const [ManualOrderSign, setManualOrderSign] = useState(1);
    // const [AutoMode, setAutoMode] = useState(false);

    // async function onClickOrder(order_str){
    //     console.log('order_str=', order_str);
    //     const dataToSubmit = {
    //         order_str : order_str,
    //         order_market : props.DefaultSymbol
    //     }
    //     const res = await axios.post('/api/manual_order', dataToSubmit);
    //     if (res.data.success===false){
    //         alert('Error: onClickOrder')
    //     }
    // }

    // async function onClickManualTrade(){
    //     setBuySellMode(order_str);
    //     setModalOpen(true);
    // }

    async function onOKTrade() {
        setModalOpen(false);
        const dataToSubmit = {
            order_str : (ManualOrderSign>0)? 'buy':'sell',
            order_symbol0 : ManualOrderSymbol0,
            order_symbol1 : ManualOrderSymbol1,
            order_qty0: parseFloat(ManualOrderQty0),
            order_qty1: parseFloat(ManualOrderQty1),
            order_price0: parseFloat(ManualOrderPrice0),
            order_price1: parseFloat(ManualOrderPrice1)
        }
        const res = await axios.post('/api/manual_order', dataToSubmit);
        if (res.data.success===false){
            alert('Error: onClickOrder')
        }
    }

    async function onCancelTrade() {
        setModalOpen(false);
    }

    //////////////// manual mode switch //////////////////
    async function onChangeManual() {
        let order_str;
        if (props.AutoMode===true) {
            order_str = 'manual_mode';
        } else {
            order_str = 'auto_mode';
        }
        const dataToSubmit = {
            order_str : order_str,
            order_symbol0 : '',
            order_symbol1 : '',
            order_qty0: 0,
            order_qty1: 0,
            order_price0: 0,
            order_price1: 0
        }
        const res = await axios.post('/api/manual_order', dataToSubmit);
        if (res.data.success===false){
            alert('Error: onClickOrder')
            return;
        }

        props.setAutoMode(!props.AutoMode);
    }
    

    return (
        <div>
            <Row justify='center' align='middle'>
                <Switch checked={props.AutoMode} onChange={onChangeManual} checkedChildren="AutoMode" unCheckedChildren="AutoMode"/>
                <Button type='primary' onClick={()=>setModalOpen(true)} style={{margin:'10px'}}>Manual Trade</Button> <br/>
            </Row>
            {/* <Row justify='space-around'>
                <Col span={6}>
                    <Button type='primary' onClick={()=>onClickOrder('clear_all_position')} style={{margin:'10px'}}>Clear All Position</Button> <br/>
                </Col>
                <Col span={6}>
                    <Button type='primary' onClick={()=>onClickOrder('update_price')} style={{margin:'10px'}}>Update Price</Button> <br/>
                </Col>
            </Row> */}
            <Modal
                title       ='Manual Trade'
                open        ={ModalOpen}
                onOk        ={onOKTrade}
                onCancel    ={onCancelTrade}
                width       ={400}
                destroyOnClose={true}
            >
                Symbol0: <Input value={ManualOrderSymbol0} onChange={e=>setManualOrderSymbol0(e.target.value)}/>
                Symbol1: <Input value={ManualOrderSymbol1} onChange={e=>setManualOrderSymbol1(e.target.value)}/>
                Price0: <Input value={ManualOrderPrice0} onChange={e=>setManualOrderPrice0(e.target.value)}/>
                Price1: <Input value={ManualOrderPrice1} onChange={e=>setManualOrderPrice1(e.target.value)}/>
                Quantity0: <Input value={ManualOrderQty0} onChange={e=>setManualOrderQty0(e.target.value)}/>
                Quantity1: <Input value={ManualOrderQty1} onChange={e=>setManualOrderQty1(e.target.value)}/>
                <Row>
                    <Radio.Group onChange={e=>setManualOrderSign(e.target.value)} value={ManualOrderSign}>
                        <Radio value={1}>Buy</Radio>
                        <Radio value={-1}>Sell</Radio>
                    </Radio.Group>
                </Row>
                <Row>
                    USD0: ${ManualOrderPrice0*ManualOrderQty0}
                </Row>
                <Row>
                    USD1: ${ManualOrderPrice1*ManualOrderQty1}
                </Row>
            </Modal>

        </div>
    )
}

export default Manual
