import './mytable.css';
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Select } from 'antd';
import axios from "axios";
import { Switch } from 'antd';

function CompoPositionsList(props) {
    console.log('CompoPositionsList start');
    
    async function onClickMarket(clientOrderId){
        console.log('onClickMarket', clientOrderId);
        const res = await axios.post("/api/exit_order_with_market", {clientOrderId});     // clientOrderId 로 enter한 position은 모두 market으로 정리함
        if (res.data.success) {
            console.log('exit_order_with_market ok')
        } else {
            alert('Error:exit_order_with_market:'+res.data.errmsg);
        }
    }

    const render = props.PositionsList && props.PositionsList.map( function (positions, index) {
        console.log("positions==>", positions);
        const side = positions.order_sign>0 ? 'Buy' : 'Sell';
        return (
            <tr key={index}>
                <td>{positions.symbol}</td>
                <td>{positions.curr_price.toFixed(2)}</td>
                <td>{side}</td>
                <td>{positions.order_price}</td>
                <td>{positions.order_qty.toFixed(4)}</td>
                <td>{positions.executedQty}</td>
                <td>{positions.avgPrice.toFixed(2)}</td>
                <td>{positions.state}</td>
                <td><Button onClick={()=>onClickMarket(positions.clientOrderId)}>Market</Button></td>
            </tr>
        )}
    )
    

    // function make_table(positionsList) {
    //     if (!positionsList) {
    //         return <></>;
    //     }
    //     else if (!positionsList.clientOrderId) {
    //         return (
    //             <tr>
    //                 <td>{positionsList.symbol}</td>
    //                 <td>주문 안함</td>
    //                 <td>{positionsList.curr_price}</td>
    //                 <td>{positionsList.order_price}</td>
    //                 <td>{positionsList.qty}</td>
    //                 <td>{positionsList.executedQty}</td>
    //                 <td>{positionsList.avgPrice}</td>
    //             </tr>
    //         )
    //     } else {
    //     }
    // }

    return (
        <div style={{margin:'15px'}}>
            <table className='mytable'>
                <thead>
                    <tr>
                        <th>Symbol</th>
                        <th>주문시 시장가</th>
                        <th>Side</th>
                        <th>주문 가격</th>
                        <th>qty</th>
                        <th>FilledQty</th>
                        <th>avgPrice</th>
                        <th>State</th>
                        <th>Cancel</th>
                    </tr>
                </thead>
                <tbody>
                    {render}
                </tbody>
            </table>
        </div>
    )
}

export default CompoPositionsList
